import { Box, Container, CssBaseline, Typography } from '@mui/material'
import InitialLoad from './components/InitialLoad'
import InitialSync from './components/InitialSync'
import TopBar from './components/TopBar'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

function App () {
  return (
    <>
      <CssBaseline />
      <TopBar />
      <Container maxWidth='sm' component='main' sx={{ pt: 8, pb: 4 }}>
        <Routes>
          <Route path='/' Component={Home} />
          <Route
            exact path='/initial-sync' element={
              <ProtectedRoute>
                <InitialSync />
              </ProtectedRoute>
          }
          />
          <Route
            exact path='/initial-load' element={
              <ProtectedRoute>
                <InitialLoad />
              </ProtectedRoute>
          }
          />
        </Routes>
      </Container>
    </>
  )
}

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  if (!isAuthenticated && inProgress === 'none') {
    return <Navigate to='/' replace />
  }
  return children
}

function Home () {
  return (
    <Box>
      <Typography component='h1' variant='h4' align='center' color='text.primary' gutterBottom>
        SAP Evision ETL Console
      </Typography>
    </Box>
  )
}

export default App
