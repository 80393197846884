import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material'
import { ArticleOutlined, ExitToApp, MenuOutlined, PublishedWithChangesOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react'
import { loginRequest } from '../AuthConfig'
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'

function TopBar () {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest)
  const { instance, accounts } = useMsal()
  const account = accounts?.length > 0 ? accounts[0] : null

  React.useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [error, login])
  const handleLogin = () => {
    login(InteractionType.Redirect, loginRequest)
  }
  const handleLogout = () => {
    instance.logoutRedirect({
      account,
      postLogoutRedirectUri: '/'
    }).catch(e => {
      // TODO
    })
  }
  return (
    <AppBar
      color='default'
      position='static'
      sx={{ borderBottom: '1px solid rgba(0,0,0,0.15)' }}
      elevation={0}
    >
      <Container>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography
            variant='h6'
            noWrap
            component={Link}
            to='/'
            sx={{
              flexGrow: 1, color: '#f50d0d', fontSize: '27px', fontWeight: 600, textDecoration: 'None'
            }}
          >
            ExxonMobil
          </Typography>
          <AuthenticatedTemplate>
            <DesktopMenu sx={{ display: { xs: 'none', md: 'block' } }} onSignOut={handleLogout} name={account?.name} />
            <MobileMenu sx={{ display: { xs: 'block', md: 'none' } }} onSignOut={handleLogout} name={account?.name} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box md={{ flexGrow: 1, pl: 2 }}>
              <Button
                size='large'
                onClick={handleLogin}
                color='inherit'
              >
                Login
              </Button>
            </Box>
          </UnauthenticatedTemplate>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
function DesktopMenu (props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  return (
    <>
      <Box sx={{ ...props.sx }} md={{ flexGrow: 1, pl: 2 }}>
        <Button
          size='large'
          color='inherit'
          component={Link}
          to='/initial-load'
        >
          Initial Load
        </Button>
        <Button
          size='large'
          sx={{ mr: 2 }}
          component={Link}
          color='inherit'
          to='/initial-sync'
        >
          Initial Sync
        </Button>
      </Box>
      <Box sx={{ ...props.sx, flexGrow: 0, pl: 2 }}>
        <Tooltip title='Open settings'>
          <IconButton
            onClick={e => setAnchorElUser(e.currentTarget)} sx={{ p: 0 }}
          >
            <Avatar alt='Sourav Paul' size='small' />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: 6 }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          elevation={1}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
        >
          <MenuItem>
            <Typography textAlign='center'>Hi, {props.name}</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            props.onSignOut()
            setAnchorElUser(null)
          }}
          >
            <Typography textAlign='center'>Logout</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  )
}
function MobileMenu (props) {
  const [enableMenu, setEnableMenu] = useState(false)
  return (
    <Box sx={{ ...props.sx }}>
      <>
        <IconButton onClick={() => setEnableMenu(true)}>
          <MenuOutlined />
        </IconButton>
        <SwipeableDrawer
          open={enableMenu}
          anchor='right'
          onOpen={() => setEnableMenu(true)}
          onClose={() => setEnableMenu(false)}
        >
          <Box sx={{ width: '50vw' }}>
            <List>
              <ListItem>
                <ListItemText>Hi, {props.name}</ListItemText>
              </ListItem>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <ListItem disablePadding>
                <ListItemButton component={Link} to='/initial-sync' onClick={() => setEnableMenu(false)}>
                  <ListItemIcon>
                    <PublishedWithChangesOutlined />
                  </ListItemIcon>
                  <ListItemText>Initial Sync</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to='/initial-load' onClick={() => setEnableMenu(false)}>
                  <ListItemIcon>
                    <ArticleOutlined />
                  </ListItemIcon>
                  <ListItemText>Initial Load</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <ListItem disablePadding>
                <ListItemButton onClick={e => { props.onSignOut(); setEnableMenu(false) }}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </SwipeableDrawer>
      </>
    </Box>
  )
}

export default TopBar
