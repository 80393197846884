import { Alert, Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { loginRequest } from '../AuthConfig'
import { useMsal } from '@azure/msal-react'
import { useState } from 'react'

const InitialLoadSchema = Yup.object().shape({
  sapSystem: Yup.string()
    .required('Please select the SAP system'),
  siteId: Yup.string()
    .required('Please enter the site code'),
  siteDesc: Yup.string()
    .required('Please enter the site description.'),
  planningPlantCodes: Yup.array()
    .of(
      Yup.string()
        .min(4, 'Plant code must be 4 charecter.')
        .max(4, 'Plant code must be 4 charecter.')
    )
    .required('Please enter the planning plant code.')
    .min(1, 'Please enter the planning plant code')
})

export default function InitialLoad () {
  const { instance, accounts } = useMsal()
  const [submitStatus, setSubmitStatus] = useState(null)
  const handleSubmit = async (values, { resetForm }) => {
    setSubmitStatus(null)
    values.sapSystem = ('' + values.sapSystem).toLocaleLowerCase()
    delete values.planningPlantCode
    try {
      const resToken = process.env.REACT_APP_ENV !== 'dev'
        ? await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        : null
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_API_URI + '/api/initial-load/extract/' + values.sapSystem,
        values,
        {
          headers: {
            'x-functions-key': process.env.REACT_APP_BACKEND_API_KEY || null,
            Authorization: resToken ? ('Bearer ' + resToken.accessToken) : null
          }
        })
      console.log('response ' + response)
      setSubmitStatus({ code: 'success', message: 'Operation Successful: ' + response.data.message })
      resetForm()
    } catch (error) {
      setSubmitStatus({
        code: 'error',
        message: 'Error Occured: ' + (error.response ? error.response.data.errorMessage : error.message)
      })
    }
  }

  return (
    <Box>
      <Box>
        <Typography component='h1' variant='h4' align='center' color='text.primary' gutterBottom>
          Initial Load
        </Typography>
        <Typography component='p' variant='h5' align='center' color='text.secondary' gutterBottom>
          Extract Initial load for the new site creation.
        </Typography>
      </Box>
      <Box sx={{ mt: submitStatus ? 4 : 7 }}>
        {
          submitStatus &&
            <Alert severity={submitStatus.code} sx={{ mb: 3 }}>
              {submitStatus.message}
            </Alert>
        }
        <Formik
          initialValues={{
            siteId: '',
            sapSystem: '',
            siteDesc: '',
            planningPlantCodes: []
          }}
          validationSchema={InitialLoadSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form onChange={() => setSubmitStatus(null)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <Autocomplete
                    required
                    value={formikProps.values.sapSystem}
                    options={['GEMS', 'NAStripes', 'EuStripes', 'APStripes']}
                    renderInput={(params) =>
                      <Field
                        required
                        as={TextField}
                        name='sapSystem'
                        helperText={<ErrorMessage name='sapSystem' />}
                        error={formikProps.errors.sapSystem && formikProps.touched.sapSystem}
                        {...params}
                        variant='outlined'
                        label='SAP System'
                      />}
                    onChange={(e, value) => {
                      formikProps.setFieldValue('sapSystem', value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Field
                    required fullWidth
                    as={TextField}
                    label='Site Code'
                    name='siteId'
                    id='siteId'
                    helperText={<ErrorMessage name='siteId' />}
                    error={formikProps.errors.siteId && formikProps.touched.siteId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required fullWidth
                    as={TextField}
                    label='Site Description'
                    name='siteDesc'
                    id='siteDesc'
                    helperText={<ErrorMessage name='siteDesc' />}
                    error={formikProps.errors.siteDesc && formikProps.touched.siteDesc}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    required freeSolo
                    options={[]} multiple
                    value={formikProps.values.planningPlantCodes}
                    renderInput={(params) =>
                      <Field
                        as={TextField}
                        name='planningPlantCode'
                        helperText={<ErrorMessage name='planningPlantCodes' />}
                        error={formikProps.errors.planningPlantCodes && formikProps.touched.planningPlantCodes}
                        {...params}
                        variant='outlined'
                        label='Planning Plant Code'
                      />}
                    onChange={(e, value) => {
                      formikProps.setFieldValue('planningPlantCodes', value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  {
                    formikProps.isSubmitting
                      ? <><CircularProgress /> <Typography>Loading...</Typography></>
                      : <Button type='submit' variant='contained' fullWidth size='large'>Submit</Button>
                  }
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}
