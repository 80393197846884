import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AUTH_TENANT_ID,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            break
          default:
        }
      }
    }
  }
}

export const loginRequest = {
  scopes: ['api://' + process.env.REACT_APP_AUTH_CLIENT_ID + '/user_impersonation']
}
