import { Alert, Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { SAP_SYSTEM, EVISION_ENDPOINTS } from '../Config'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../AuthConfig'
import axios from 'axios'

const InitialSyncSchema = Yup.object().shape({
  sapSystem: Yup.string()
    .required('Please select the SAP system'),
  siteId: Yup.string()
    .required('Please enter the site id'),
  evisionEndpoint: Yup.string()
    .required('Please Select the Evision endpoint.'),
  planningPlantCodes: Yup.array(
    Yup.string()
      .min(4, 'Plant code must be 4 charecter.')
      .max(4, 'Plant code must be 4 charecter.')
  ),
  startDate: Yup.date()
    .required('Please Select the Start Date'),
  endDate: Yup.date()
    .nullable()
})

export default function InitialSync () {
  const { instance, accounts } = useMsal()
  const [submitStatus, setSubmitStatus] = useState(null)
  const handleSubmit = async (values, { resetForm }) => {
    setSubmitStatus(null)
    values.sapSystem = ('' + values.sapSystem).toLocaleLowerCase()
    values.evisionEndpoint = values.evisionEndpoint.split(' - ')[0].toLocaleLowerCase()
    delete values.planningPlantCode
    try {
      const resToken = process.env.REACT_APP_ENV !== 'dev'
        ? await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        : null
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_API_URI + '/api/equipment/sync/delta/' + values.sapSystem,
        values,
        {
          headers: {
            'x-functions-key': process.env.REACT_APP_BACKEND_API_KEY || null,
            Authorization: resToken ? ('Bearer ' + resToken.accessToken) : null
          }
        })
      console.log('response ' + response)
      setSubmitStatus({
        code: 'success',
        message: `Synchronization Successful: ${response.data.message}. Updated Equipment Ids: ${response.data.data}`
      })
      resetForm()
    } catch (error) {
      setSubmitStatus({
        code: 'error',
        message: 'Error Occured: ' + (error.response ? error.response.data.errorMessage : error.message)
      })
    }
  }

  return (
    <Box>
      <Box>
        <Typography component='h1' variant='h4' align='center' color='text.primary' gutterBottom>
          Initial Synchronization
        </Typography>
        <Typography component='p' variant='h5' align='center' color='text.secondary' gutterBottom>
          Trigger Initial Synchronization for the new site creation.
        </Typography>
      </Box>
      <Box sx={{ mt: submitStatus ? 4 : 7 }}>
        {
          submitStatus &&
            <Alert severity={submitStatus.code} sx={{ mb: 3 }}>
              {submitStatus.message}
            </Alert>
        }
        <Formik
          initialValues={{
            siteId: '',
            sapSystem: '',
            evisionEndpoint: '',
            planningPlantCodes: [],
            startDate: null,
            endDate: null
          }}
          validationSchema={InitialSyncSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form onChange={() => setSubmitStatus(null)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    required
                    value={formikProps.values.sapSystem}
                    options={SAP_SYSTEM}
                    renderInput={(params) =>
                      <Field
                        required
                        as={TextField}
                        name='sapSystem'
                        helperText={<ErrorMessage name='sapSystem' />}
                        error={formikProps.errors.sapSystem && formikProps.touched.sapSystem}
                        {...params}
                        variant='outlined'
                        label='SAP System'
                      />}
                    onChange={(e, value) => {
                      formikProps.setFieldValue('sapSystem', value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required fullWidth
                    as={TextField}
                    label='Site Id'
                    name='siteId'
                    id='siteId'
                    helperText={<ErrorMessage name='siteId' />}
                    error={formikProps.errors.siteId && formikProps.touched.siteId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    required
                    value={formikProps.values.evisionEndpoint}
                    options={EVISION_ENDPOINTS}
                    renderInput={(params) =>
                      <Field
                        required
                        as={TextField}
                        name='evisionEndpoint'
                        helperText={<ErrorMessage name='evisionEndpoint' />}
                        error={formikProps.errors.evisionEndpoint && formikProps.touched.evisionEndpoint}
                        {...params}
                        variant='outlined'
                        label='Evision Endpoint'
                      />}
                    onChange={(e, value) => {
                      formikProps.setFieldValue('evisionEndpoint', value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    options={[]} multiple
                    value={formikProps.values.planningPlantCodes}
                    renderInput={(params) =>
                      <Field
                        as={TextField}
                        name='planningPlantCode'
                        helperText={<ErrorMessage name='planningPlantCodes' />}
                        error={formikProps.errors.planningPlantCodes && formikProps.touched.planningPlantCodes}
                        {...params}
                        variant='outlined'
                        label='Planning Plant Code'
                      />}
                    onChange={(e, value) => {
                      formikProps.setFieldValue('planningPlantCodes', value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDateTimePicker
                      disableFuture
                      label='Start DateTime'
                      name='startDate'
                      format='YYYY-MM-DDTHH:mm:ss'
                      id='startDate'
                      value={formikProps.values.startDate}
                      onAccept={value => formikProps.setFieldValue('startDate', value)}
                      slotProps={{
                        textField: {
                          required: true,
                          fullWidth: true,
                          helperText: <ErrorMessage name='startDate' />,
                          error: formikProps.errors.startDate && formikProps.touched.startDate
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDateTimePicker
                      disableFuture
                      label='End DateTime'
                      name='endDate'
                      format='YYYY-MM-DDTHH:mm:ss'
                      id='endDate'
                      value={formikProps.values.endDate}
                      onAccept={value => formikProps.setFieldValue('endDate', value, true)}
                      slotProps={{
                        actionBar: {
                          actions: ['clear', 'cancel', 'accept']
                        },
                        textField: {
                          fullWidth: true,
                          helperText: <ErrorMessage name='endDate' />,
                          error: formikProps.errors.endDate && formikProps.touched.endDate
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  {
                    formikProps.isSubmitting
                      ? <><CircularProgress /> <Typography>Loading...</Typography></>
                      : <Button type='submit' variant='contained' fullWidth size='large'>Submit</Button>
                  }
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}
